import Client, {setSessionKey} from "../api/Client";
import {LogoutUserInfo, UserInfo} from '../types/Auth'


export async function AuthGoogle(handle: (userInfo:UserInfo) => void, token: string) {
    try {
        const response = await Client.post('/auth/google', {token: token});
        if (response.data.success) {
            setSessionKey(response.data.session_id);
        }
        handle({
            loggedIn: response.data.success,
            email: response.data.email,
            name: response.data.name,
            userId: response.data.userId,
            avatar: response.data.avatar,
            sessionId: response.data.session_id
        });
    } catch (error) {
        console.error("Error:", error);
    }
}

export async function Logout(handle: (userInfo:UserInfo) => void, sessionId: string) {
    await Client.post('/auth/logout', {session_id: sessionId});
    handle(LogoutUserInfo);
}