import React, {useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';

import {GenerateText, GetCategoryAll} from "../../../api/Npc";
import { Collapse, Divider, Slider, Typography} from "@mui/material";
import { ExpandLess, ExpandMore} from "@mui/icons-material";
import CategoryEdit from "../../../components/npc/generator/CategoryEdit";
import Results from "../../../components/npc/generator/Results";
import Button from "@mui/material/Button";
import PageWithTitle from "../../../components/common/PageWIthTitle";
import {CategoryValueInfo, CategoryValues} from "../../../types/Npc";

const DefaultTexts = Array.from({ length: 10 }, () => "대사 생성 전 입니다.")


const Generator: React.FC = () => {
    const [ isLoading, setIsLoading ] = useState(true);
    const [ isShowLog, setIsShowLog ] = useState(false);
    const [ resultCount, setResultCount ] = useState<number>(3);
    const [ results, setResults] = useState<string[]>(DefaultTexts);
    const [ dialog, setDialog ] = useState<string>('');
    const [ prompt, setPrompt ] = useState<string>('');
    const [ categoryValues, setCategoryValues ] = useState<CategoryValues>({});
    const [ selectedCategoryValue, setSelectedCategoryValue ] = useState<CategoryValueInfo[]>([]);


    const generateText = async () => {
        setResults(DefaultTexts)
        const results = await GenerateText(dialog, resultCount, selectedCategoryValue.map((value) => value.id));
        setPrompt(results.prompt);
        setResults(results.generated);
    }

    const LoadCategoryValues = async () => {
        setIsLoading(true)
        const data = await GetCategoryAll();
        setCategoryValues(data);
        setIsLoading(false);
    }

    useEffect(() => {
        try {
            LoadCategoryValues();
        } catch(error) {
            console.error(error);
            setCategoryValues({});
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
//        console.log(selected);
    }, [selectedCategoryValue]);
    return (
        <PageWithTitle title="대사 생성기">
            <Typography
                variant="h5"
                component="div">
                입력
            </Typography>
            <TextField
                multiline
                fullWidth
                rows={5}
                label="대사"
                variant="outlined"
                onChange={(event) => {setDialog(event.target.value)}}
            />
            <Divider />
            <CategoryEdit
                isLoading={isLoading}
                categoryValues={categoryValues}
                onReload={LoadCategoryValues}
                onChange={setSelectedCategoryValue}
            />
            <Divider />
            <Typography
                variant="body1"
                component="div">
                동시 생성 갯수
            </Typography>
            <Slider
                defaultValue={3}
                valueLabelDisplay="on"
                step={1}
                marks={true}
                min={1}
                max={10}
                onChange={(event, value) => {setResultCount(value as number)}}
            />
            <Divider />
            <Button
                variant="contained"
                onClick={generateText}>
                생성
            </Button>
            <Divider />
            <Results count={resultCount} results={results}/>
            <Divider />
            <Typography
                variant="h5"
                onClick={() => setIsShowLog(!isShowLog)}
                component="div">
                Background Log
                {isShowLog ? <ExpandLess /> : <ExpandMore />}
            </Typography>
            <Collapse in={isShowLog} timeout="auto" unmountOnExit>
                <Typography
                    variant="h6"
                    onClick={() => setIsShowLog(!isShowLog)}
                    component="div">
                    입력
                </Typography>
                <Typography
                    component="div"
                    sx={{ flexGrow: 1, whiteSpace: 'pre-line' }}>
                    {prompt}
                </Typography>
            </Collapse>
        </PageWithTitle>

    );
}

export default Generator;