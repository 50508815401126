import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

interface MessageDialogProps {
    title?: string;
    message?: string;
    openState : {
        open : boolean;
        setOpen : (value: boolean) => void;
    }
    onClose? : () => void;
}
const MessageDialog: React.FC<MessageDialogProps> = (props) => {
    const { open, setOpen } = props.openState;

    const handleClose = () => {
        setOpen(false);
        props.onClose?.();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="ok-dialog-title"
            aria-describedby="ok-dialog-description"
        >
            <DialogTitle id="ok-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="ok-dialog-description">
                    {props.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                    닫기
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MessageDialog;