import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField } from '@mui/material';

interface TextInputDialogProps {
    title?: string;
    message?: string;
    text_label?: string;
    openState : {
        open : boolean;
        setOpen : (value: boolean) => void;
    }
    onChange? : (value: string) => void;
    onConfirm? : (value: string) => void;
    onCancel? : () => void;
}
const TextInputDialog: React.FC<TextInputDialogProps> = (props) => {
    const { open, setOpen } = props.openState;
    const [inputValue, setInputValue] = useState('');

    const handleClose = () => {
        setInputValue('');
        setOpen(false);
        props.onCancel?.();
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
        props.onChange?.(event.target.value);
    };

    const handleConfirm = () => {
        setOpen(false);
        props.onConfirm?.(inputValue);
        setInputValue('');
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="popup-title"
            aria-describedby="popup-description"
        >
            <DialogTitle id="popup-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="popup-description">
                    {props.message}
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label={props.text_label}
                    type="text"
                    fullWidth
                    value={inputValue}
                    onChange={handleInputChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleConfirm} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TextInputDialog;