import React from 'react';
import {IconButton} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { GoogleLogin } from '@react-oauth/google';
import {AuthGoogle, Logout} from "../../api/Auth";
import {LogoutUserInfo, UserInfo} from '../../types/Auth'

interface AuthProps {
    userInfo : UserInfo;
    handle : (userInfo:UserInfo) => void;
}

const LoginoutButton : React.FC<AuthProps> = (authProps) => {
    const onSuccess = async (response: any) => {
        console.log("Login Success:", response);
        AuthGoogle(authProps.handle, response.credential);
    }

    const onError = () => {
        console.error('Login Error')
        authProps.handle(LogoutUserInfo);
    }

    if (authProps.userInfo.loggedIn) {
        return (
            <IconButton size="small" onClick={() => {Logout(authProps.handle, authProps.userInfo.sessionId)}}>
                <LogoutIcon />
                Logout
            </IconButton>
        );
    }

    return (
        <GoogleLogin
            onSuccess={onSuccess}
            onError={onError}
        />
    );
}

export default LoginoutButton;