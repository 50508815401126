import React from "react";
import {DialogResultProps, DialogResultType} from "../../../types/UI";
import {Box, Typography} from "@mui/material";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Button from "@mui/material/Button";
import ResultTabs from "./ResultTabs";
import ResultList from "./ResultList";

const Results : React.FC<DialogResultProps> = (props) => {
    const [ viewType, setViewType    ] = React.useState<DialogResultType>(DialogResultType.Tabs);

    const changeViewType = () => {
        if (viewType === DialogResultType.Tabs) {
            setViewType(DialogResultType.CollapseList);
        } else {
            setViewType(DialogResultType.Tabs);
        }
    }

    const resultTabs = (<ResultTabs count={props.count} results={props.results}/>);
    const resultCollapseList = (<ResultList count={props.count} results={props.results}/>);
    const resultComponent = () => {
        switch(viewType) {
            case DialogResultType.Tabs:
                return resultTabs;
            case DialogResultType.CollapseList:
                return resultCollapseList;
        }
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Typography
                variant="h5"
                component="div">
                결과
                <Button onClick={changeViewType}>
                    <SwapHorizIcon/>
                </Button>
                <Button onClick={() => navigator.clipboard.writeText(props.results.join('\n'))}>
                    <ContentCopyIcon/>
                </Button>
            </Typography>
            {resultComponent()}
        </Box>
    );
}

export default Results;