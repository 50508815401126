import React from "react";
import {Container, Stack, Typography} from "@mui/material";

interface PageWithTitleProps {
    title: string;
    children?: React.ReactNode;
}

const PageWithTitle : React.FC<PageWithTitleProps> = (props) => {
    return (
        <Container>
            <Stack
                direction="column"
                spacing={2}
                justifyContent="flex-start"
                alignItems="stretch"
                style={{marginTop: 10}}>
                <Typography
                    variant="h3"
                    component="div">
                    {props.title}
                </Typography>
                {props.children}
            </Stack>
        </Container>
    )
}

export default PageWithTitle;