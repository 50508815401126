import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import {
    AppBar,
    Box,
    Collapse,
    Container,
    CssBaseline,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Toolbar,
    Typography
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

import {GoogleOAuthProvider} from "@react-oauth/google";
import Avatar from "@mui/material/Avatar";
import {LogoutUserInfo} from "./types/Auth";
import {Entry, MultiEntry, SingleEntry } from "./types/UI";
import {ExpandLess, ExpandMore } from "@mui/icons-material";
import LoginoutButton from "./components/common/LoginoutButton";

import Menus, {LogoutMenus} from "./menu";

const GOOGLE_CLIENT_ID = '277975203591-6m63e69klgoomkmg9pnqmit7j9288rf2.apps.googleusercontent.com';
const drawerWidth = 200;
const theme = createTheme();

const getChildren = (entry: Entry) => {
    return (entry as MultiEntry).children
};

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const App: React.FC = () => {
    const [routes, setRoutes ] = useState<Entry[]>([]);
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [open, setOpen] = useState<{ [key: string]: boolean }>({});
    const [userInfo, setUserInfo] = React.useState(LogoutUserInfo);

    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };

    const toggleAccordian = (menu: string) => {
        setOpen((prev) => ({ ...prev, [menu]: !prev[menu] }));
    };

    useEffect(() => {
        if (userInfo.loggedIn) {
            setRoutes(Menus);
        } else {
            setRoutes(LogoutMenus);
        }
    },[userInfo]);

    const BuildEntry = (entry: Entry, index: number, level: number) => {
        if (getChildren(entry) !== undefined) {
            return BuildMultiEntry(entry as MultiEntry, index, level);
        } else {
            return BuildSingleEntry(entry as SingleEntry, index, level);
        }
    }
    const BuildSingleEntry = (route: SingleEntry, index: number, level: number) => {
        return (
            <ListItem key={index} component={Link} to={route.path} sx={{ pl: level * 2 }}>
                <ListItemText primary={route.name} />
            </ListItem>
        );
    }

    const BuildMultiEntry = (route: MultiEntry, index: number, level: number) => {
        return (
            <React.Fragment key={index}>
                <ListItem onClick={() => toggleAccordian(route.name)}>
                    <ListItemText primary={route.name} sx={{ pl: level * 2 }} />
                    {open[route.name] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open[route.name]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {route.children.map((subRoute, subIndex) =>
                            BuildEntry(subRoute, subIndex, level + 1))}
                    </List>
                </Collapse>
                <Divider />
            </React.Fragment>
        );
    }

    const BuildRoute = (route: Entry, index: number) => {
        if (getChildren(route) !== undefined) {
            return BuildMultiRoute(route as MultiEntry, index);
        } else {
            return BuildSingleRoute(route as SingleEntry, index);
        }
    }
    const BuildMultiRoute: (route: MultiEntry, index: number) => (any[] | React.JSX.Element)[] = (route: MultiEntry, index: number) => {
        return (route.children.map((subRoute, subIndex) => BuildRoute(subRoute, subIndex)));
    }
    const BuildSingleRoute = (route: SingleEntry, index: number) => {
        return (
            <Route key={index} path={route.path} element={<route.component/>}/>);
    }

    return (
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
                <Router>
                    <CssBaseline />
                    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={toggleDrawer}
                                edge="start"
                                sx={{ marginRight: '36px' }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" noWrap component="div">
                                Blanc Tools
                            </Typography>
                            <Typography variant="h6" noWrap component="div" sx={{ marginLeft: 'auto' }}>
                                {userInfo.loggedIn ? `${userInfo.name}  (${userInfo.email})` : 'Not Logged In'}
                            </Typography>
                            <Avatar alt={userInfo.name} src={userInfo.loggedIn ? userInfo.avatar : ''} sx={{ marginLeft: '10px' }} />
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: drawerWidth,
                                boxSizing: 'border-box',
                            },
                        }}
                        variant="persistent"
                        anchor="left"
                        open={openDrawer}
                    >
                        <DrawerHeader>
                            <IconButton onClick={toggleDrawer}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </DrawerHeader>
                        <List component="nav">
                            {routes.map((route, index) => BuildEntry(route, index, 1))}
                            <LoginoutButton userInfo={userInfo} handle={setUserInfo} />
                        </List>
                    </Drawer>
                    <Box
                        component="main"
                        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
                    >
                        <DrawerHeader />
                        <Container maxWidth="lg">
                            <Routes>
                                {routes.map((route, index) => BuildRoute(route, index))}
                            </Routes>
                        </Container>
                    </Box>
                </Router>
            </Box>
        </ThemeProvider>
        </GoogleOAuthProvider>
    );
}

export default App;