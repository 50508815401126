import React from "react";
import {CategoryValueInfo, PresetInfo} from "../../../types/Npc";
import {IconButton, List, ListItem, ListItemText} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import ListItemIcon from "@mui/material/ListItemIcon";

type PresetListProps = {
    presetsState : {
        presets : PresetInfo[];
        setPresets : (value: PresetInfo[]) => void;
    }
    selectedPresetIndexState : {
        selectedPresetIndex : number | null;
        setSelectedPresetIndex : (value: number | null) => void;
    }
}

const PresetList : React.FC<PresetListProps> = (props) => {
    const { presets, setPresets } = props.presetsState;
    const { selectedPresetIndex, setSelectedPresetIndex } = props.selectedPresetIndexState;

    const [ search, setSearch ] = React.useState<string>('');

    const onClick = (preset: PresetInfo) => {
        setSelectedPresetIndex(preset.id)
    }

    const onDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, preset: PresetInfo) => {
        event.stopPropagation()
        if( preset.id == selectedPresetIndex ) {
            setSelectedPresetIndex(null);
        }
    }

    const onSearchUpdated = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    const filteredPreset = presets.filter((preset) => preset.name.includes(search));

    const elements = filteredPreset.map((preset) => {
        return (
            <ListItem
                button
                key={preset.id}
                selected={preset.id === selectedPresetIndex}
                onClick={() => onClick(preset)}>
                <ListItemText primary={preset.name} />
            </ListItem>
        );
    });

    return (
        <>
            <TextField
                id="preset-name"
                label="Preset Name"
                variant="outlined"
                onChange={onSearchUpdated}
            />
            <List
                sx={{
                    width: '100%',
                    maxWidth: 360,
                    bgcolor: 'background.paper',
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: 300,
                    '& ul': { padding: 0 },
                }}>
                {elements}
            </List>
        </>
    );
}

export default PresetList;