import React, {useEffect, useState } from 'react';
import {Divider, Stack} from "@mui/material";
import AutoCompleteCombo from "../../common/AutoCompleteCombo";
import {CategoryInfo, CategoryValueInfo, CategoryValues} from "../../../types/Npc";
import {AutoCompleteEntry, SubValueType} from "../../../types/UI";
import {AddCategory, AddCategoryValue} from "../../../api/Npc";


interface ComboBoxesProps {
    isLoading: boolean;
    categoryValues: CategoryValues;
    preset: CategoryValueInfo[];

    onReload : () => Promise<void>;
    onChange : (value: CategoryValueInfo[]) => void;
}

const CategoryComboBoxes : React.FC<ComboBoxesProps> = (props) => {
    const [ selected, setSelected ] = useState<CategoryValueInfo[]>([]);

    const [ categories, setCategories ] = useState<AutoCompleteEntry[]>([]);
    const [ values, setValues ] = useState<SubValueType>({} as SubValueType);

    const [ selectedCategories, setSelectedCategories ] = useState<AutoCompleteEntry[]>([]);
    const [ selectedValues, setSelectedValues ] = useState<SubValueType>({} as SubValueType);

    const BuildCategories = () => {
        return Object.keys(props.categoryValues).map((key) => {
            const numKey = Number(key);
            return {
                text: props.categoryValues[numKey].category.name,
                payload: props.categoryValues[numKey].category
            } as AutoCompleteEntry;
        });
    }

    const BuildValues = () => {
        return Object.keys(props.categoryValues).reduce((acc: SubValueType, key: string) => {
            const numKey = Number(key);
            const entry = props.categoryValues[numKey];
            acc[entry.category.name] = entry.values.map((value) => {
                return {
                    text: value.name,
                    payload: value
                } as AutoCompleteEntry;
            });
            return acc;
        },
        {} as SubValueType);
    }

    const addCategory = async (entry : AutoCompleteEntry ) => {
        await AddCategory(entry.text);
        await props.onReload();
    }

    const addValue = async (entry : AutoCompleteEntry, category_id : number ) => {
        await AddCategoryValue(entry.text, category_id);
        await props.onReload();
    }

    const UpdateSelected = () => {
        const newValues = selectedCategories.reduce((acc: CategoryValueInfo[], categoryEntry: AutoCompleteEntry) => {
            return selectedValues[categoryEntry.text].reduce((subAcc: CategoryValueInfo[], entry: AutoCompleteEntry) => {

                subAcc.push(entry.payload as CategoryValueInfo);
                return subAcc;
            }, acc);
        }, []);
        setSelected(newValues);
        props.onChange(newValues);
    }

    const ApplyPreset = (preset: CategoryValueInfo[]) => {
        const newCategories = preset.reduce((acc: AutoCompleteEntry[], valueInfo: CategoryValueInfo) => {
            const category = categories.find((entry) => (entry.payload as CategoryInfo).id === valueInfo.category_id);
            if ( category !== undefined ) {
                if (acc.findIndex((entry) => entry.text === category.text) === -1) {
                    acc.push(category);
                }
            }
            return acc;
        }, [] as AutoCompleteEntry[]);

        const newValues = preset.reduce((acc: SubValueType, valueInfo: CategoryValueInfo) => {
            const category = categories.find((entry) => (entry.payload as CategoryInfo).id === valueInfo.category_id);
            if ( category !== undefined ) {
                if (acc[category.text] === undefined) {
                    acc[category.text] = [];
                }
                const value = values[category.text].find((entry) => (entry.payload as CategoryValueInfo).id === valueInfo.id);
                if (value !== undefined) {
                    acc[category.text].push(value);
                }
            }
            return acc;
        }, {} as SubValueType);
        setSelectedCategories(newCategories);
        setSelectedValues(newValues);
    }

    useEffect(() => {
        UpdateSelected();
    }, [selectedCategories, selectedValues]);


    useEffect(() => {
        ApplyPreset(props.preset);
    }, [props.preset]);

    useEffect(() => {
        setCategories(BuildCategories());
        setValues(BuildValues());
    }, [props.categoryValues]);

    return (
        <Stack
            direction="column"
            spacing={2}
            justifyContent="flex-start"
            alignItems="stretch"
            style={{marginTop: 10, marginLeft:1}}>
            <AutoCompleteCombo
                isLoading={props.isLoading}
                title="카테고리"
                options={categories}
                selected={selectedCategories}
                handleNewItem={addCategory}
                onChange={setSelectedCategories}/>
            <Divider />
            {
                selectedCategories.map((entry, _) => {
                    if(selectedValues[entry.text] === undefined) {
                        const newValue: SubValueType = {
                            ...selectedValues,
                            [entry.text]: [] as AutoCompleteEntry[] // AutoCompleteEntry[] 배열로 초기화
                        };

                        setSelectedValues(newValue);
                    }
                    const category_id = (entry.payload as CategoryInfo).id;
                    return (
                        <AutoCompleteCombo
                            isLoading={props.isLoading}
                            key={entry.text}
                            title={entry.text}
                            handleNewItem={async (entry) => {await addValue(entry, category_id)}}
                            options={values[entry.text]}
                            selected={selectedValues[entry.text]}
                            onChange={(vec) => {
                                const newValue: SubValueType = {
                                    ...selectedValues,
                                    [entry.text]: vec
                                };

                                setSelectedValues(newValue);
                            }}
                        />
                    );
                })
            }
        </Stack>
    );
}

export default CategoryComboBoxes;