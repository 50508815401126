import React from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button"
import { DialogResultProps } from "../../../types/UI";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    const copyToClipboard = async (text: string) => {
        try {
            if( text !== undefined ) {
                await navigator.clipboard.writeText(text);
            }
        } catch (err) {
            console.error('Failed to copy text:', err);
        }
    };
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tab-panel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            <Button onClick={() => copyToClipboard(children as string)}>Copy</Button>
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tab-panel-${index}`,
    };
}

const ResultTabs : React.FC<DialogResultProps> = (props) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="results">
                    {
                        Array.from({length: props.count}, (_, i) => (
                            <Tab label={`결과 ${i+1}`} {...a11yProps(i)} />
                        ))
                    }
                </Tabs>
            </Box>
            {
                Array.from({length: props.count}, (_, i) => (
                    <CustomTabPanel value={value} index={i}>
                        {props.results[i]}
                    </CustomTabPanel>
                ))
            }
        </>
    );
}

export default ResultTabs;