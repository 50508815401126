import React from "react"
import {Container, Stack, Typography} from "@mui/material";

const History : React.FC = () => {
    return (
        <Container>
            <Stack
                direction="column"
                spacing={2}
                justifyContent="flex-start"
                alignItems="stretch"
                style={{marginTop: 10}}>
                <Typography
                    variant="h3"
                    component="div">
                    대사 생성 기록
                </Typography>
            </Stack>
        </Container>
    )
}

export default History;