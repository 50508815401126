import React from "react";
import {Typography} from "@mui/material";

const DashboardPage: React.FC = () => {
    return (
        <Typography>
            블랑팀 개발툴입니다.
        </Typography>
    );
};
export default DashboardPage;