import DashboardPage from "./pages/dashboard";
import Generator from "./pages/npc/dialog/Generator";
import History from "./pages/npc/dialog/History";
import LogoutPage from "./pages/logout";

import {Entry} from "./types/UI";

const Menus : Entry[] = [
    { path: '/', name: 'Dashboard', component: DashboardPage },
    {
        name: 'NPC',
        children: [
            { path: '/npc/dialog/generator', name: '대사 생성', component:  Generator },
            { path: '/npc/dialog/history', name: '대사 생성 기록', component:  History }
        ]
    },
];

export const LogoutMenus : Entry[] = [
    { path: '/', name: '', component: LogoutPage },
]
export default Menus;