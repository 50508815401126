import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

interface YesNoDialogProps {
    title?: string;
    message?: string;
    openState : {
        open : boolean;
        setOpen : (value: boolean) => void;
    }
    onYes? : () => void;
    onNo? : () => void;
    onCancel? : () => void;
}
const YesNoDialog: React.FC<YesNoDialogProps> = (props) => {
    const { open, setOpen } = props.openState;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        props.onCancel?.();
    };

    const handleYes = () => {
        setOpen(false);
        props.onYes?.();
    };

    const handleNo = () => {
        setOpen(false);
        props.onNo?.();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="yes-no-dialog-title"
            aria-describedby="yes-no-dialog-description"
        >
            <DialogTitle id="yes-no-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="yes-no-dialog-description">
                    {props.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleNo} color="primary">
                    아니오
                </Button>
                <Button onClick={handleYes} color="primary" autoFocus>
                    예
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default YesNoDialog;