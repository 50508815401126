import Client from '../api/Client';
import { CategoryValueInfo, CategoryValues, GeneratedText, PresetInfo} from "../types/Npc";

export async function GetCategoryAll() : Promise<CategoryValues> {
    try {
        const response = await Client.get('npc/dialogs/category');
        const data = response.data;

        const categories = data.categories.reduce((acc: CategoryValues, row: any) => {
            acc[row.id] = {
                category: {
                    id: row.id,
                    name: row.category
                },
                values: []
            };
            return acc;
        },{});

        return data.values.reduce((acc: CategoryValues, row: any) => {
            if (!acc[row.category_id]) {
                acc[row.category_id] = {
                    category: {
                        id: row.category_id,
                        name: "undefined"
                    },
                    values: []
                };
            }
            acc[row.category_id].values.push({
                id: row.id,
                category_id: row.category_id,
                name: row.value
            } as CategoryValueInfo);
            return acc;
        },categories);
    } catch(error) {
        console.error("Error:", error);
        return {};
    }
}
export async function AddCategory(text: string) : Promise<void> {
    try {
        await Client.post('npc/dialogs/category', {name: text});
    } catch (error) {
        console.error("Error:", error);
    }
}

export async function AddCategoryValue(text: string, category_id: number) : Promise<void> {
    try {
        await Client.post('npc/dialogs/value', {value: text, category_id: category_id});
    } catch (error) {
        console.error("Error:", error);
    }
}

export async function GenerateText(text: string, count: number, values: number[] ) : Promise<GeneratedText> {
    try {
        const response = await Client.get('npc/dialogs/generate',{ params:{text: text, count: count, values: values}});
        const data = response.data;
        return {
            prompt: data.prompt,
            generated: data.generated
        };
    } catch(error) {
        console.error("Error:", error);
        return {
            prompt: "",
            generated: []
        };
    }
}

export async function AddPreset(name: string, values: CategoryValueInfo[]) : Promise<boolean> {
    try {
        await Client.post('npc/dialogs/preset', {name: name, values: values.map((value) => value.id)});
        return true;
    } catch(error) {
        console.error("Error:", error);
        return false;
    }
}
export async function GetPresets(categoryValues: CategoryValues): Promise<PresetInfo[]> {
    try {
        const response = await Client.get('npc/dialogs/preset');
        const data = response.data.presets;

        const valuesInfoList = Object.keys(categoryValues).reduce((acc: CategoryValueInfo[], kwy) => {
            return categoryValues[Number(kwy)].values.reduce((subAcc: CategoryValueInfo[], value) => {
                subAcc.push(value);
                return subAcc;
            }, acc);
        }, [] as CategoryValueInfo[]);

        return Object.keys(data).reduce((acc: PresetInfo[], key: string) => {
            const values = data[key].values.reduce((acc: CategoryValueInfo[], value: any) => {
                const valueId = Number(value);
                const found = valuesInfoList.find((info) => info.id === valueId);
                if( found !== undefined ) {
                    acc.push(found);
                }
                return acc;
            }, [] as CategoryValueInfo[]);
            acc.push({
                id: Number(key),
                name: data[key].name,
                values: values
            });
            return acc;
                return acc;
            }, [] as PresetInfo[]);
    } catch(error) {
        console.log("Error:", error);
        return [] as PresetInfo[];
    }

}