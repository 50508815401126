import React, {useEffect, useState} from "react";
import {CategoryValueInfo, CategoryValues, PresetInfo} from "../../../types/Npc";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CategoryComboBoxes from "./CategoryComboBoxes";
import PresetList from "./PresetList";
import TextInputDialog from "../../common/DialogBox/TextInputDialog";
import YesNoDialog from "../../common/DialogBox/YesNoDialog";
import {AddPreset, GetPresets} from "../../../api/Npc";
import MessageDialog from "../../common/DialogBox/MessageDialog";

interface CategoryEditProps {
    isLoading: boolean;
    categoryValues: CategoryValues;

    onReload : () => Promise<void>;
    onChange : (value: CategoryValueInfo[]) => void;
}

const CategoryEdit : React.FC<CategoryEditProps> = (props) => {
    const [ selectedPresetIndex, setSelectedPresetIndex ] = useState<number | null>(null);
    const [ presets, setPresets ] = React.useState<PresetInfo[]>([]);
    const [ ableToSave, setAbleToSave ] = useState<boolean>(false);

    const [ toApplyPreset, setToApplyPreset ] = useState<CategoryValueInfo[]>([]);
    const [ toSavePreset, setToSavePreset ] = useState<CategoryValueInfo[]>([]);


    const [ openPresetNameDialog, setOpenPresetNameDialog ] = useState<boolean>(false);
    const [ openApplyPresetDialog, setOpenApplyPresetDialog ] = useState<boolean>(false);
    const [ openMessageDialog, setOpenMessageDialog ] = useState<boolean>(false);
    const [ messageTitle, setMessageTitle ] = useState<string>('');
    const [ messageBody, setMessageBody ] = useState<string>('');

    const showMessageDialog = (title: string, body: string) => {
        setOpenMessageDialog(true);
        setMessageTitle(title);
        setMessageBody(body);
    }
    const handleAddPreset = () => {
        setOpenPresetNameDialog(true);
    }

    const handleApplyPreset = () => {
        setOpenApplyPresetDialog(true);
    }

    const handleApplyPresetConfirmed = () => {
        if( selectedPresetIndex === null ) {
            return;
        }
        const newPreset = presets.find((preset) => preset.id === Number(selectedPresetIndex))?.values || []
        setToApplyPreset(newPreset);
    }

    const handleAddPresetConfirmed = (name: string) => {
        AddPreset(name, toSavePreset)
            .then((success) => {
                showMessageDialog("프리셋 추가", success ? "프리셋이 추가되었습니다." : "프리셋 추가에 실패했습니다.");
                if( success) {
                    LoadPresets();
                }
            });
    }

    const LoadPresets = async () => {
        const newPresets = await GetPresets(props.categoryValues);
        setPresets(newPresets)
        setSelectedPresetIndex(null);
    }

    const GetCurrentPresetName = () => {
        if( selectedPresetIndex === null ) {
            return '';
        }
        const preset = presets.find((preset) => preset.id === selectedPresetIndex);
        return preset ? preset.name : '';
    }

    useEffect(() => {
        LoadPresets();
    }, [props.categoryValues])

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={3}>
                <PresetList
                    presetsState={{ presets, setPresets }}
                    selectedPresetIndexState={{ selectedPresetIndex: selectedPresetIndex, setSelectedPresetIndex: setSelectedPresetIndex }}/>
            </Grid>
            <Grid item xs={2}>
                <Grid container direction="column" alignItems="center">
                    <Button
                        sx={{ my: 0.5, width: "1%" }}
                        variant="outlined"
                        size="large"
                        onClick={handleApplyPreset}
                        //disabled={preset.length === 0 || selectedPreset === null}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="large"
                        onClick={handleAddPreset}
                        //disabled={!ableToSave}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                    <TextInputDialog
                        title="프리셋 추가"
                        message="프리셋 이름을 입력하세요."
                        text_label="프리셋 이름"
                        openState={{ open: openPresetNameDialog, setOpen: setOpenPresetNameDialog }}
                        onConfirm={handleAddPresetConfirmed}
                    />
                    <YesNoDialog
                        title="프리셋 적용"
                        message={`프리셋(${GetCurrentPresetName()})을 적용하시겠습니까?`}
                        openState={{ open: openApplyPresetDialog, setOpen: setOpenApplyPresetDialog }}
                        onYes={handleApplyPresetConfirmed}
                    />
                    <MessageDialog
                        title={messageTitle}
                        message={messageBody}
                        openState={{ open: openMessageDialog, setOpen: setOpenMessageDialog }}
                    />
                </Grid>
            </Grid>
            <Grid item xs={7}>
                <CategoryComboBoxes
                    isLoading={props.isLoading}
                    categoryValues={props.categoryValues}
                    preset={toApplyPreset}
                    onReload={props.onReload}
                    onChange={(value: CategoryValueInfo[]) => {
                        setAbleToSave(value.length > 0 );
                        setToSavePreset(value);
                        props.onChange(value);
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default CategoryEdit;
