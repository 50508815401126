import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import {AutoCompleteEntry} from "../../types/UI";

const filter = createFilterOptions<AutoCompleteEntry>();

interface AutoCompleteComboProps {
    isLoading: boolean;
    sx?: any;
    title: string;
    selected : AutoCompleteEntry[]
    options : AutoCompleteEntry[];

    handleNewItem? : (entry : AutoCompleteEntry) => Promise<void>
    onChange : (value: AutoCompleteEntry[]) => void;
}

const AutoCompleteCombo: React.FC<AutoCompleteComboProps> = (props) => {
    return (
        <Autocomplete
            onChange={async (event, newValue) => {
                if (!Array.isArray(newValue))
                    return;

                const vec = newValue as AutoCompleteEntry[];
                if (vec.length > 0 && vec[vec.length - 1].inputValue !== undefined) {
                    vec[vec.length - 1].text = vec[vec.length - 1].inputValue as string;
                    vec[vec.length - 1].inputValue = undefined;

                    if ( props.handleNewItem !== undefined ) {
                        await props.handleNewItem(vec[vec.length - 1]);
                    }
                    return;
                }
                props.onChange(vec);
            }}

            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const {inputValue} = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.text);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        text: `Add "${inputValue}"`,
                        payload: {}
                    });
                }
                return filtered;
            }}
            multiple
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="combo-box-autocomplete"
            options={props.options}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.text;
            }}
            loading={props.isLoading}
            renderOption={(props, option) => {
                const {key, ...optionProps} = props;
                return (
                    <li key={key} {...optionProps}>
                        {option.text}
                    </li>
                );
            }}
            value={props.selected}
            sx={props.sx}
            freeSolo
            renderInput={(params) => (
                <TextField {...params} label={props.title}/>
            )}
        />
    );
}

export default AutoCompleteCombo;