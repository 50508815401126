import React from "react";
import {Typography} from "@mui/material";

const LogoutPage: React.FC = () => {
    return (
        <Typography>
            세션이 종료되었습니다. 로그인해주세요
        </Typography>
    );
};
export default LogoutPage;