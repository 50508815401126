import axios from 'axios';

function getSessionKey() : string | null {
    return localStorage.getItem('sessionId');
}

export function setSessionKey(sessionId: string) {
    localStorage.setItem('sessionId', sessionId);
}

const Client = axios.create({
    baseURL: 'https://tool.dev.blanc.shiftupcorp.com/api/v1',
    headers: {
        'Content-Type': 'application/json',
    }
})

Client.interceptors.request.use(config => {
    const sessionId = getSessionKey();
    if (sessionId) {
        config.headers['Authorization'] = `Bearer ${sessionId}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

export default Client;