import React, {useState} from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button"
import { DialogResultProps } from "../../../types/UI";
import {Collapse, Divider, Typography} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

interface ResultEntryProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function ResultEntry(props: ResultEntryProps) {
    const { children, value, index, ...other } = props;

    const copyToClipboard = async (text: string) => {
        try {
            if( text !== undefined ) {
                await navigator.clipboard.writeText(text);
            }
        } catch (err) {
            console.error('Failed to copy text:', err);
        }
    };
    return (
        <div
            role="tabpanel"
            id={`simple-tab-panel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box sx={{ p: 3 }}>{children}</Box>
            <Button onClick={() => copyToClipboard(children as string)}>Copy</Button>
        </div>
    );
}

const ResultList : React.FC<DialogResultProps> = (props) => {
    const initialOpen = Array.from({length: props.count}, (_, i) => true);
    const [open, setOpen] = useState(initialOpen);
    const [value, setValue] = React.useState(0);
    const toggleAccordian = (idx: number) => {
        setOpen((prev) => ({ ...prev, [idx]: !prev[idx] }));
    };

    return (
        <>
            {
                Array.from({length: props.count}, (_, i) => (
                    <>
                    <Divider/>
                    <Typography
                        variant="body1"
                        component="div"
                        onClick={() => toggleAccordian(i)}>
                        결과 {i + 1}
                        {open[i] ? <ExpandLess /> : <ExpandMore />}
                    </Typography>
                    <Collapse in={open[i]} timeout="auto" unmountOnExit>
                        <ResultEntry value={value} index={i}>
                            {props.results[i]}
                        </ResultEntry>
                    </Collapse>
                    </>
                ))
            }
        </>
    );
}

export default ResultList;