export type UserInfo = {
    loggedIn: boolean;
    name: string;
    email: string;
    userId: string;
    avatar: string;
    sessionId: string;
};

export const LogoutUserInfo : UserInfo = {loggedIn: false, name: '', email: '', userId: '', avatar: '', sessionId: ''};
