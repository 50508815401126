import React from "react";
import {Collapse} from "@mui/material";

export type SingleEntry = {
    path: string;
    name: string;
    component: React.FC<{}>;
}

export type MultiEntry = {
    name: string;
    children: Entry[];
}

export type Entry = SingleEntry | MultiEntry;

export type AutoCompleteEntry = {
    inputValue? : string;
    text: string;
    payload: any;
}

export type SubValueType = {
    [key: string] : AutoCompleteEntry[];
}

export enum DialogResultType {
    Tabs,
    CollapseList
}

export interface DialogResultProps {
    count: number;
    results: string[];
}